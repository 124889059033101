import React, { HTMLAttributes } from 'react';
import { IconType } from 'react-icons';
import { FaUser } from 'react-icons/fa';
import classNames from 'classnames';
import { Icon } from '../icon/icon';
import { AvatarPull, AvatarSize } from './types';

export type AvatarProps = {
  img?: string;
  size?: AvatarSize;
  pull?: AvatarPull;
  initials?: string;
  fallbackIcon?: IconType;
  inheritTextSize?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const Avatar = ({
  img,
  pull,
  size = AvatarSize.Small,
  fallbackIcon = FaUser,
  initials,
  className,
  inheritTextSize,
  ...props
}: AvatarProps) => {
  return (
    <div
      className={classNames(
        {
          avatar: true,
          [`avatar-${size}`]: true,
          bordered: !img && !initials,
          [`u-pull-${pull}`]: !!pull,
          'rounded-full p-0.5': !!initials,
          'text-xl': !!initials && !inheritTextSize,
        },
        className,
      )}
      {...props}
    >
      {img ? <img src={img} alt="avatar" /> : initials ? initials : fallbackIcon ? <Icon icon={fallbackIcon} /> : null}
    </div>
  );
};
