import { Editor, type IAllProps } from '@tinymce/tinymce-react';
import { TinyMCE, type Editor as OtherEditor } from 'tinymce';

import { forwardRef, useImperativeHandle, useRef, useState } from 'react';

export { WysiwygToolbarBuilder } from './wysiwyg-toolbar-builder';

const TINYMCE_API_KEY = 'bu1xgmeebnrf7pfnmxudjvarv0nqerfe7kv1y7490orgnkti';

export type WysiwygProps = IAllProps & {
  menuBar?: boolean;
  init?: Partial<TinyMCE['init']>;
  tinyMceApiKey?: string;
};

export type EditorRef = {
  editor?: OtherEditor;
  insertContent: (content: string) => void;
  getContent: () => string;
  setContent: (content: string) => void;
  openPreview: (content: string) => Promise<void>;
};

export const useWysiwygRef = () => useRef<EditorRef>(null);

// TODO: Formik version for JobPosting Page formik.

let beforePreviewContent = '';
let previewOpened = false;

export const WysiwygEditor = forwardRef<EditorRef, WysiwygProps>(
  ({ menuBar = false, init = {}, tinyMceApiKey, ...rest }, forwardedRef) => {
    const [editor, setEditor] = useState<OtherEditor | undefined>(undefined);
    const editorRef = useRef<Editor>(null);

    useImperativeHandle(
      forwardedRef,
      () => ({
        editor: editor,
        getContent() {
          return editorRef?.current?.editor?.getContent() || '';
        },
        insertContent(content) {
          editor?.insertContent(content);
        },
        setContent(content) {
          editor?.setContent(content);
        },
        async openPreview(content) {
          const existingContent = editor?.getContent();
          beforePreviewContent = existingContent || '';
          editor?.setContent(content);
          editor?.execCommand('mcePreview');
        },
      }),
      [editor, editorRef],
    );

    return (
      <Editor
        apiKey={tinyMceApiKey || TINYMCE_API_KEY}
        init={{
          convert_urls: false,
          invalid_elements: 'html,head,doctype',
          icons: 'thin',
          width: '100%',
          min_height: 64,
          plugins: 'preview autolink media link table help code template lists',
          noneditable_class: 'dynamic',
          menubar: menuBar,
          toolbar:
            'undo redo | blocks fontsize | bold italic underline strikethrough table alignleft aligncenter alignright | numlist bullist | backcolor forecolor removeformat | code',
          statusbar: false,
          init_instance_callback: (editor: OtherEditor) => {
            editor.on('ExecCommand', (e: { command: string }) => {
              if (e.command === 'mcePreview' && !previewOpened) {
                previewOpened = true;
              } else if (e.command === 'mceFocus' && beforePreviewContent?.length > 0 && previewOpened) {
                const previousContent = String(beforePreviewContent);
                setTimeout(() => {
                  editor.setContent(previousContent);
                  beforePreviewContent = '';
                }, 50);
              }
            });
          },
          ...init,
        }}
        {...rest}
        ref={editorRef}
        onInit={(_, editor) => {
          setEditor(editor);
        }}
      />
    );
  },
);

type ControlledProps = WysiwygProps & {
  onChange: (field: string) => (val: string) => void;
};

export const ControlledWysiwyg = forwardRef<EditorRef, ControlledProps>(({ value, onChange, ...props }, ref) => {
  const handleChange: (val: string, editor: OtherEditor) => void = (val) => {
    onChange(val);
  };

  return <WysiwygEditor {...props} ref={ref} value={value} onEditorChange={handleChange} />;
});
