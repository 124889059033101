import React from 'react';
import { useTranslation } from 'next-i18next';
import { FaPen, FaTrash, FaTrashRestore } from 'react-icons/fa';
import { Button, ButtonColor, ButtonSize, ButtonVariant } from '../core/button/button';
import { Box } from '../core/layout/box/box';
import { ConfirmationButton } from '../core/button/confirmation-button';
import { Grid } from '../core/layout/grid/grid';
import { Icon, IconSize } from '../core/icon/icon';
import { LOCALE_LABELS, TemplateContent } from './types';

export type TemplateListProps<Content extends TemplateContent> = {
  templates: Content[];
  onDeleteTemplate: (template: Content) => void;
  onEditTemplate: (template: Content) => void;
};

export const TemplateList = <Content extends TemplateContent>({
  templates,
  onEditTemplate,
  onDeleteTemplate,
}: TemplateListProps<Content>) => {
  const { t } = useTranslation();
  if (!templates || !templates.length) {
    return null;
  }

  const onClickEdit = (template: Content) => () => onEditTemplate(template);
  const onClickDelete = (template: Content) => () => onDeleteTemplate(template);

  return (
    <Grid.Container fluid className="email-templates" data-testid="email-templates">
      {templates.map((template) => (
        <Grid.Row key={template.locale}>
          <Grid.Column key={template.locale} colspan={12} p={0} m={0} mb={4}>
            <Box
              className="email-template"
              data-testid="email-template"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              alignContent="center"
              borderSize={1}
              borderColor="light-grey"
              borderRadius={2}
              ml={0}
              mr={0}
              p={2}
            >
              <h5>{LOCALE_LABELS[template.locale]}</h5>
              <Box className="actions" alignSelf="center" alignItems="center" alignContent="center">
                <Button
                  variant={ButtonVariant.Icon}
                  color={ButtonColor.Blue}
                  size={ButtonSize.Small}
                  aria-label={`Edit ${LOCALE_LABELS[template.locale]}`}
                  onClick={onClickEdit(template)}
                >
                  <Icon icon={FaPen} size={IconSize.Medium} />
                </Button>

                <ConfirmationButton
                  className="u-mt-0"
                  variant={ButtonVariant.Icon}
                  color={ButtonColor.Remove}
                  size={ButtonSize.Small}
                  confirmation={
                    template.locale === 'en-US'
                      ? {
                          title: t('emailTemplates.restore.confirmTitle'),
                          message: t('emailTemplates.restore.confirmMessage'),
                          confirm: t('emailTemplates.restore.confirmButton'),
                          cancel: t('emailTemplates.restore.cancelButton'),
                        }
                      : {
                          title: t('emailTemplates.delete.confirmTitle'),
                          message: t('emailTemplates.delete.confirmMessage'),
                          confirm: t('emailTemplates.delete.confirmButton'),
                          cancel: t('emailTemplates.delete.cancelButton'),
                        }
                  }
                  confirmButtonProps={{ color: ButtonColor.Remove }}
                  aria-label="Delete"
                  onClick={onClickDelete(template)}
                >
                  <Icon icon={template.locale === 'en-US' ? FaTrashRestore : FaTrash} size={IconSize.Medium} />
                </ConfirmationButton>
              </Box>
            </Box>
          </Grid.Column>
        </Grid.Row>
      ))}
    </Grid.Container>
  );
};
