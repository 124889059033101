import React from 'react';
import * as RadixSwitch from '@radix-ui/react-switch';
import { Box } from '../layout/box/box';
import classnames from 'classnames';
import classNames from 'classnames';

export type SwitchProps = {
  id: string;
  className?: string;
  label?: string;
  title?: string;
  subLabel?: string | React.ReactNode;
  icon?: React.ReactNode;
  checked: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
  hideSwitchThumbText?: boolean;
};

export const Switch = ({
  icon,
  id,
  className,
  disabled,
  label,
  title,
  subLabel,
  onChange,
  checked,
  hideSwitchThumbText,
}: SwitchProps) => {
  return (
    <Box className={classnames('switch-container', className)}>
      <RadixSwitch.Root
        className="switch-root"
        id={id}
        name={id}
        checked={checked}
        onCheckedChange={!disabled ? onChange : undefined}
        title={title}
        disabled={disabled}
        data-testid={`switch-${id}`}
      >
        <RadixSwitch.Thumb
          className={classNames('switch-thumb', {
            'switch-thumb-no-text': hideSwitchThumbText,
          })}
        />
      </RadixSwitch.Root>
      {label || subLabel ? (
        <Box display="flex" flexDirection="column" className="switch-info">
          {label ? (
            <label className="label text-offblack" htmlFor={id}>
              {label}
            </label>
          ) : null}
          {subLabel ? (
            <label
              className={classNames('sublabel mb-0', {
                'text-gray-600': disabled,
                'text-offblack': !disabled,
              })}
              htmlFor={id}
            >
              {subLabel}
            </label>
          ) : null}
        </Box>
      ) : null}

      {icon ? <Box className="switch-icon">{icon}</Box> : null}
    </Box>
  );
};
