import React, { FC, useEffect, useRef, useState } from 'react';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import { PlainObject } from '@mongodb-js/charts-embed-dom/dist/types/types';

interface ChartProps {
  token: string;
  baseUrl: string;
  filter?: PlainObject;
  chartId: string;
  height: string;
  width?: string;
}

export const Chart: FC<ChartProps> = ({ token, baseUrl, filter, chartId, height, width }) => {
  const sdk = new ChartsEmbedSDK({
    baseUrl: baseUrl,
  });
  const chartDiv = useRef<HTMLDivElement>(null);
  const [rendered, setRendered] = useState(false);
  const [chart] = useState(
    sdk.createChart({
      chartId: chartId,
      height: height,
      width: width,
      theme: 'light',
      showAttribution: false,
      getUserToken: () => {
        return token;
      },
    }),
  );

  useEffect(() => {
    if (chart) {
      chart
        .render(chartDiv.current as HTMLElement)
        .then(() => setRendered(true))
        .catch((err: Error) => console.log('Error during Charts rendering.', err));
    }
  }, [chart]);

  useEffect(() => {
    if (rendered && chart) {
      chart.setFilter({}).catch((err: Error) => console.log('Error while filtering.', err));
    }
  }, [chart, filter, rendered]);

  return <div className="chart w-full" ref={chartDiv} />;
};

export default Chart;
