import classNames from 'classnames';
import React, { ReactElement, useState } from 'react';
import { BsX } from 'react-icons/bs';
import { Button, ButtonSize } from '../button/button';

export type AlertActionProps = (
  | {
      type?: 'link';
      label: string;
    }
  | {
      type: 'button';
      label: string | React.ReactNode;
      buttonProps?: Omit<React.ComponentProps<typeof Button>, 'children'>;
    }
) &
  (
    | {
        href: string;
        onClick?: never;
      }
    | {
        href?: never;
        onClick: () => void;
      }
  );

export enum AlertSeverity {
  Info = 'info',
  Error = 'error',
  Success = 'success',
}

export type AlertProps = {
  action?: AlertActionProps;
  className?: string;
  dismissKey?: string;
  icon?: React.ReactNode;
  severity?: AlertSeverity;
  testId?: string;
} & (
  | {
      message: string | React.ReactNode;
      title?: never;
    }
  | {
      message?: never;
      title: string;
    }
  | {
      message: string | React.ReactNode;
      title: string;
    }
);

export const AlertAction = (props: AlertActionProps) => {
  if (!props) {
    return null;
  }

  if (props.type === 'button') {
    return (
      <Button
        size={ButtonSize.Small}
        onClick={() => {
          if (props.onClick) {
            return props.onClick();
          }

          window.location.href = props.href;
        }}
        {...(props.buttonProps || {})}
      >
        {props.label}
      </Button>
    );
  }

  return (
    <a
      href={props.href}
      onClick={
        props.onClick
          ? (e) => {
              e.preventDefault();
              props.onClick();
            }
          : undefined
      }
    >
      {props.label}
    </a>
  );
};

const buildDismissKey = (key: string): string => {
  return 'alerts.' + key;
};

export const Alert = ({
  action,
  title,
  message,
  severity = AlertSeverity.Info,
  dismissKey,
  icon,
  testId,
  className = '',
}: AlertProps): ReactElement | null => {
  const isDismissed = !!dismissKey && !!localStorage.getItem(buildDismissKey(dismissKey));
  const [displayed, setDisplayed] = useState(!isDismissed);
  const displayDismissButton = !!dismissKey;

  if (!displayed) {
    return null;
  }

  const handleDismiss = (): void => {
    localStorage.setItem('alerts.' + dismissKey, 'true');
    setDisplayed(false);
  };

  return (
    <div
      className={classNames(
        'box-border flex flex-1 rounded-[4px] border-0 border-l-[4px] border-solid px-[16px] py-[6px] shadow-light',
        severity === AlertSeverity.Error && 'border-l-pink bg-pink-200',
        severity === AlertSeverity.Info && 'border-l-teal-400 bg-teal-100',
        severity === AlertSeverity.Success && 'border-l-green-500 bg-green-200',
        className,
      )}
      {...(testId ? { 'data-testid': testId } : {})}
    >
      <div className="flex flex-1 flex-row">
        {!!icon && (
          <div
            className={classNames(
              'mr-[16px] flex items-center justify-center border-0 border-r-[2px] border-solid pr-[16px] text-[32px]',
              severity === AlertSeverity.Error && 'border-r-pink text-pink',
              severity === AlertSeverity.Info && 'border-r-teal-400 text-teal-400',
              severity === AlertSeverity.Success && 'border-r-green-500 text-green-500',
            )}
          >
            {icon}
          </div>
        )}
        <div className="flex flex-col w-full">
          {(title || displayDismissButton) && (
            <div className="w-full flex flex-row flex-end justify-between">
              {title ? <div className="title font-bold p-0 m-0">{title}</div> : null}
              {displayDismissButton && (
                <BsX
                  role="button"
                  onClick={handleDismiss}
                  className="text-offblack cursor-pointer rounded my-auto hover:bg-black/20"
                />
              )}
            </div>
          )}
          {message ? <p className="!m-0 font-normal">{message}</p> : null}
        </div>
        {action && (
          <div>
            <AlertAction {...action} />
          </div>
        )}
      </div>
    </div>
  );
};
