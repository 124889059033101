import { ModalButton } from '../../core/modal/modal-button';
import { TemplateManager } from '../template-manager';
import { defaultMediaBackendAdapter } from '../../media-management/hooks/default-media-backend-adapter';
import { EditTemplateButtonProps } from '../types';
import { defaultGenericTemplateBackendAdapter } from '../hooks/default-generic-template-backend-adapter';

export const EditTemplateButton = ({
  apiBaseUrl,
  employerId,
  templateType,
  templateName,
  tinyMceApiKey,
  title,
  ...buttonProps
}: EditTemplateButtonProps) => {
  const backendAdapter = defaultGenericTemplateBackendAdapter({ apiBaseUrl, employerId, templateType });
  const mediaBackendAdapter = defaultMediaBackendAdapter({ apiBaseUrl, employerId });

  return (
    <ModalButton
      className="unstyled-button"
      modalProps={{
        className: 'modal',
        size: 'medium',
        modal: false,
        overlay: true,
        onInteractOutside: (e) => {
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();
        },
      }}
      body={() => (
        <TemplateManager
          contentCssUrl={`${apiBaseUrl}api/v2/templates/email/style.css`}
          templateType={templateType}
          templateName={templateName}
          templateTitle={title}
          tinyMceApiKey={tinyMceApiKey}
          backendAdapter={backendAdapter}
          mediaBackendAdapter={mediaBackendAdapter}
        />
      )}
      {...buttonProps}
    ></ModalButton>
  );
};
