/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { Button, ButtonProps } from '../core/button/button';
import { PopupModal, useCalendlyEventListener } from 'react-calendly';
import { CalendlyEvent, CalendlyPrefill } from './types';

export type CalendlyBookingButtonProps = Omit<ButtonProps, 'onClick'> & {
  rootElementId?: string;
  calendlyUrl: string;
  calendlyEvent?: string;
  prefill: CalendlyPrefill;
  onEventBooked?: (event: CalendlyEvent) => void;
};

export const CalendlyBookingButton = ({
  rootElementId = '__next',
  calendlyUrl,
  calendlyEvent,
  prefill,
  onEventBooked,
  ...buttonProps
}: CalendlyBookingButtonProps) => {
  const [open, setOpen] = React.useState(false);
  useCalendlyEventListener({
    onEventScheduled: (e) => {
      setOpen(false);
      onEventBooked?.({
        calendlyEventUri: e.data.payload.event.uri,
        calendlyEventInviteeUri: e.data.payload.invitee.uri,
      });
    },
  });

  const onClick = () => {
    setOpen(true);
  };

  return (
    <>
      <Button {...buttonProps} onClick={onClick} />
      <PopupModal
        url={`${calendlyUrl}${calendlyEvent ? `/${calendlyEvent}` : ''}`}
        // @ts-ignore
        rootElement={typeof document !== 'undefined' ? document.getElementById(rootElementId)! : null}
        onModalClose={() => setOpen(false)}
        open={open}
        prefill={{ ...prefill, name: `${prefill.firstName} ${prefill.lastName}` }}
        pageSettings={{
          backgroundColor: 'FDFCFA',
          textColor: '7a7a7a',
          primaryColor: '0D4608',
          hideGdprBanner: true,
        }}
      />
    </>
  );
};
