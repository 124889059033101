import classNames from 'classnames';
import { FC, ReactNode } from 'react';

type FontWeights = 'light' | 'default' | 'medium' | 'semibold' | 'bold';
type TextSize = 'small' | 'default' | 'large' | 'larger' | 'x-large' | 'xx-large';
type TextWrap = 'nowrap' | 'wrap';
type TextCasing = 'title-case' | 'uppercase' | 'lowercase' | 'capitalize';

export type TextProps = {
  className?: string;
  wrapperClassName?: string;
  children: React.ReactNode;
  weight?: FontWeights;
  color?: string;
  backgroundColor?: string;
  size?: TextSize;
  wrap?: TextWrap;
  casing?: TextCasing;
  center?: boolean;
  'data-testid'?: string;
};

const Base: FC<Omit<TextProps, 'wrapperClassName'>> = ({
  className = '',
  children,
  weight = 'default',
  color,
  size = 'default',
  wrap,
  casing,
  backgroundColor,
  ...rest
}) => {
  let content = children;
  const classes = classNames('text', className, weight, size, wrap, casing);
  if (casing === 'title-case' && children) {
    // This will break <strong> and <em> tags that might appear inside a span, but we don't use those in titles anyway.
    const childrenString: string = children.toString().toLowerCase();
    content = childrenString[0].toUpperCase() + childrenString.slice(1);
  }
  return (
    <span className={classes} {...rest} style={{ backgroundColor, color }}>
      {content}
    </span>
  );
};

const Body: FC<TextProps> = ({ children, wrapperClassName, className, ...rest }) => {
  return (
    <Base {...rest} className={classNames(className, wrapperClassName)}>
      {children}
    </Base>
  );
};

const Paragraph: FC<TextProps> = ({ center, wrapperClassName, children, ...rest }) => {
  const classes = classNames({ center }, wrapperClassName);

  return (
    <p className={classes}>
      <Base {...rest}>{children}</Base>
    </p>
  );
};

const Icon: FC<TextProps> = ({ className, children, wrapperClassName, ...rest }) => {
  const classes = classNames('icon', className, wrapperClassName);
  return (
    <Base className={classes} {...rest}>
      {children}
    </Base>
  );
};

const Link: FC<TextProps> = ({ children, className, wrapperClassName, ...rest }) => {
  const classes = classNames('link', className, wrapperClassName);
  return (
    <Base className={classes} {...rest}>
      {children}
    </Base>
  );
};

type TypeProps = {
  children: ReactNode;
  className?: string;
} & Record<string, unknown>;

const PageHeading: FC<TypeProps> = ({ children, className, ...rest }) => (
  <h1 className={classNames('text-28 leading-none font-semibold mb-[60px]', className)} {...rest}>
    {children}
  </h1>
);

const EmployerPageHeading: FC<TypeProps> = ({ children, className, ...rest }) => (
  <h1 className={classNames('text-24 leading-none font-medium mb-[60px]', className)} {...rest}>
    {children}
  </h1>
);

const SecondaryHeading: FC<TypeProps> = ({ children, className, ...rest }) => (
  <h2 className={classNames('text-24 leading-[28px] font-medium mb-[12px]', className)} {...rest}>
    {children}
  </h2>
);

const SectionTitle: FC<TypeProps> = ({ children, className, ...rest }) => (
  <div className={classNames('bg-blue-500 rounded text-white font-bold py-[6px] px-[20px] mb-4', className)} {...rest}>
    {children}
  </div>
);

const SectionParagraph: FC<TypeProps> = ({ children, className, ...rest }) => (
  <p className={classNames('text-14 font-light text-center', className)} {...rest}>
    {children}
  </p>
);

export const Text = {
  Base,
  Body,
  Paragraph,
  Icon,
  Link,
  PageHeading,
  EmployerPageHeading,
  SecondaryHeading,
  SectionTitle,
  SectionParagraph,
};
