import { useEffect, useState } from 'react';
import { Icon, IconColor } from '../icon/icon';
import { MdCheckCircle, MdContentCopy } from 'react-icons/md';

let timeout: NodeJS.Timeout;

export const CopyText = ({ text }: { text: string }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    timeout = setTimeout(() => setCopied(false), 2000);
  };

  useEffect(() => {
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="flex flex-row items-center justify-between bg-gray-75 px-6 py-3 gap-2 rounded-md">
      <input
        type="text"
        value={text}
        readOnly
        className="!bg-gray-75 !border-none !p-0 !m-0 flex-1 overflow-ellipsis whitespace-nowrap overflow-hidden !h-[25px] !text-blue-800"
      />
      <Icon icon={copied ? MdCheckCircle : MdContentCopy} onClick={handleCopy} color={IconColor.Blue} />
    </div>
  );
};
