import React, { useMemo } from 'react';
import { useField } from 'formik';
import { GenericFormInputProps } from './types';
import { FormInputContainer } from './form-input-container';
import { WithContext as ReactTags } from 'react-tag-input';
import type { Tag } from 'react-tag-input/types/components/SingleTag';
import { Icon } from '../icon/icon';
import classnames from 'classnames';

export type FormTagInputProps<T> = GenericFormInputProps & {
  suggestions: T[];
  idField?: keyof T;
  labelField?: keyof T;
  allowNew?: boolean;
  buildNewFromTag?: (tag: Tag) => T;
};

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export const FormTagInput = <T,>({
  idField,
  labelField,
  icon,
  allowNew,
  buildNewFromTag,
  suggestions: suggestionsProp,
  containerClassName,
  containerTestId,
  name,
  label,
  tooltip,
  required,
  optional,
  placeholder,
  testId,
  renderBefore,
  renderAfter,
}: FormTagInputProps<T>) => {
  const [focused, setFocused] = React.useState(false);
  const [{ value, onBlur: onBlurFormik }, { error, touched }, { setTouched, setValue }] = useField<Array<T>>(name);

  const handleFocus = () => setFocused(true);

  const handleDelete = (deletedIndex: number) => {
    setTouched(true);
    setValue(value.filter((tag, index) => index !== deletedIndex));
  };

  const handleAddition = (tag: Tag) => {
    setTouched(true);

    const origValue = value || [];
    const suggestion = suggestionsProp.find((s) => (idField ? s[idField] : s?.toString()) === tag.id);
    if (suggestion) {
      setValue([...origValue, suggestion]);
    } else if (allowNew && buildNewFromTag) {
      setValue([...origValue, buildNewFromTag(tag)]);
    }
  };

  const handleDrag = (tag: Tag, currPos: number, newPos: number) => {
    setTouched(true);
    const newValue = value.slice();

    const suggestion = suggestionsProp.find((s) => (idField ? s[idField] : s?.toString()) === tag.id);
    if (suggestion) {
      newValue.splice(currPos, 1);
      newValue.splice(newPos, 0, suggestion);
      setValue(newValue);
    }
  };

  const handleBlur = (text: string) => {
    setFocused(false);
    onBlurFormik(name);

    // if there is text, add it as a tag (if it doesn't already exist)
    if (text?.length) {
      const cleanedText = text.trim();
      handleAddition({ id: cleanedText, text: cleanedText, className: '' });
    }
  };

  const suggestions = useMemo<Array<Tag>>(
    () =>
      suggestionsProp && Array.isArray(suggestionsProp)
        ? suggestionsProp.map((suggestion) => ({
            id: String(idField ? suggestion[idField] : suggestion?.toString()),
            text: String(labelField ? suggestion[labelField] : suggestion?.toString()),
            className: '',
          }))
        : [],
    [idField, labelField, suggestionsProp],
  );

  const tags = useMemo<Array<Tag>>(() => {
    return value && Array.isArray(value)
      ? value.map((tag) => {
          return {
            id: String(idField ? tag[idField] : tag?.toString()),
            text: String(labelField ? tag[labelField] : tag?.toString()),
            className: '',
          };
        })
      : [];
  }, [idField, labelField, value]);

  return (
    <FormInputContainer
      name={name}
      error={error}
      touched={touched}
      label={label}
      tooltip={tooltip}
      required={required}
      optional={optional}
      testId={testId}
      placeholder={placeholder}
      containerClassName={containerClassName}
      containerTestId={containerTestId}
      renderBefore={renderBefore}
      renderAfter={renderAfter}
    >
      <fieldset className={classnames({ 'with-icon': !!icon })}>
        {icon ? <Icon icon={icon} className="form-input-icon" /> : null}
        {/* @ts-ignore */}
        <ReactTags
          id={name}
          name={name}
          classNames={{
            tags: focused ? 'ReactTags__tags focus' : 'ReactTags__tags',
            tag: 'pill pill-primary pill-small',
            tagInput: '',
            tagInputField: '',
            selected: '',
            remove: '',
            suggestions: '',
            activeSuggestion: '',
            editTagInput: '',
            editTagInputField: '',
            clearAll: '',
          }}
          placeholder={placeholder}
          aria-label={label}
          tags={tags}
          suggestions={suggestions}
          delimiters={delimiters}
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          handleDrag={handleDrag}
          handleInputFocus={handleFocus}
          handleInputBlur={handleBlur}
          inputFieldPosition="bottom"
          autocomplete
          autofocus={false}
          inline={true}
          minQueryLength={1}
        />
      </fieldset>
    </FormInputContainer>
  );
};
