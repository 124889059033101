import classnames from 'classnames';
import React, { Fragment, ReactNode } from 'react';
import { DatagridColumn, DatagridObject, DatagridTableBodyProps } from './types';
import { FaChevronRight } from 'react-icons/fa';
import { Ghost } from '../ghost/ghost';

const getValueFromRow = <T extends DatagridObject>(
  obj: T,
  accessor: DatagridColumn<T>['accessor'],
  idx: number,
): ReactNode => {
  if (typeof accessor === 'function') {
    return accessor(obj, idx);
  }

  if (!accessor.includes('.')) {
    return String(obj[accessor]);
  }

  const pathParts = accessor.split('.');
  let currentObj = obj;
  for (const pathPart of pathParts) {
    currentObj = currentObj?.[pathPart];
  }

  return String(currentObj || '');
};

export const DatagridTableBody = <Data extends DatagridObject, Sort>(props: DatagridTableBodyProps<Data, Sort>) => {
  const [expandedRowIds, setExpandedRowIds] = React.useState<Data['_id'][]>([]);

  if (props.loading) {
    if (props.ghostLoad) {
      return (
        <tbody>
          {[1, 2, 3, 4, 5, 6, 7, 8].map((row, rowIdx) => (
            <tr key={rowIdx}>
              {props.columns.map((column, colIdx) => (
                <td key={colIdx} style={{ width: column.width }}>
                  {column.ghostLoader ?? <Ghost.Text className="whitespace-nowrap overflow-hidden" />}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      );
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={props.columns.length}>{props.loadingText || 'Loading...'}</td>
          </tr>
        </tbody>
      );
    }
  }

  if (!props.data?.length) {
    return (
      <tbody>
        <tr>
          <td colSpan={props.columns.length}>{props.noDataText || 'No data'}</td>
        </tr>
      </tbody>
    );
  }

  const toggleExpanded = (row: Data) => {
    if (props.expandMultiple) {
      if (expandedRowIds.includes(row[props.idColumn])) {
        setExpandedRowIds((rowIds) => rowIds.filter((id) => id !== row[props.idColumn]));
      } else {
        setExpandedRowIds((rowIds) => [...rowIds, row[props.idColumn]]);
      }
    } else {
      setExpandedRowIds((rowIds) => {
        if (rowIds.includes(row[props.idColumn])) {
          return [];
        } else {
          return [row[props.idColumn]];
        }
      });
    }
  };

  const ExpandComponent = props.expandComponent;
  const onClickRow = (row: Data, canExpand: boolean) => () => {
    if (canExpand) {
      toggleExpanded(row);
    } else if (props.onClickRow) {
      props.onClickRow(row);
    }
  };

  return (
    <tbody>
      {props.data.map((row: Data, rowIdx) => {
        const canExpand = !!ExpandComponent && (props.canExpand ? props.canExpand(row) : true);
        const expanded = canExpand ? expandedRowIds.includes(row[props.idColumn]) : false;

        return (
          <Fragment key={row[props.idColumn] || rowIdx}>
            <tr
              onClick={onClickRow(row, canExpand)}
              className={classnames({ clickable: canExpand || props.onClickRow })}
              data-testid={row[props.idColumn]}
            >
              {props.columns.map((column, colIdx) => (
                <td key={colIdx} style={{ width: column.width }}>
                  {getValueFromRow(row, column.accessor, rowIdx)}
                </td>
              ))}
              {props.expandComponent && (
                <td>
                  <FaChevronRight
                    className={classnames('transition-all transform focus:outline-none', {
                      'text-blue-1000': canExpand,
                      'text-blue-500': !canExpand,
                      'rotate-90': expanded,
                    })}
                    data-testid="expand-row"
                    onKeyUp={(e): void => {
                      if (e.key === 'Enter') {
                        onClickRow(row, canExpand)();
                      }
                    }}
                    role="button"
                    tabIndex={0}
                  />
                </td>
              )}
            </tr>
            {ExpandComponent ? (
              <tr
                className={classnames('expanded-row', {
                  'expanded-row--expanded': expanded,
                })}
              >
                <td colSpan={props.columns.length + 1} width="100%">
                  <div>{expanded ? <ExpandComponent row={row} /> : null}</div>
                </td>
              </tr>
            ) : null}
          </Fragment>
        );
      })}
    </tbody>
  );
};
