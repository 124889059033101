import { ReactElement, LegacyRef } from 'react';
import { useDrag } from 'react-dnd';
import { IoMdClose } from 'react-icons/io';
import { PipelineCardDropResult, PipelineCardProps } from './types';

export const PipelineCard = ({
  children,
  color,
  draggable = true,
  id,
  onDragEnd,
  onClick,
  onRemove,
  pipelineId,
  type = 'CARD',
}: PipelineCardProps): ReactElement => {
  const [, drag, dragPreview] = useDrag(() => ({
    canDrag: () => {
      return draggable;
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult() as PipelineCardDropResult;
      const receivingPipelineId = dropResult && dropResult.receivingPipelineId;
      onDragEnd?.(pipelineId, receivingPipelineId, item.id);
    },
    item: {
      id: id,
    },
    type,
  }));

  return (
    <div
      className="pipeline__card"
      onClick={() => onClick?.(pipelineId, id)}
      ref={drag as unknown as LegacyRef<HTMLDivElement>}
    >
      <div
        className={`pipeline__card-content pipeline__card-content--${color}`}
        ref={dragPreview as unknown as LegacyRef<HTMLDivElement>}
      >
        {onRemove && <IoMdClose className="pipeline__card-remove" onClick={() => onRemove(pipelineId, id)} />}
        {children}
      </div>
    </div>
  );
};

export default PipelineCard;
